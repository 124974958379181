.App {
  text-align: center;
  font-family: Lato;
  overflow: hidden;
}

.smaller {
  font-size: 33px;
}

.normal {
  font-weight: 300 !important;
}

.van {
  width: 905px;
  height: 480px;
  background-image: url("../images/van.png");
  left: 80%;
  top: 50px;
  display: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #98D2D9;
  font-weight: 300;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #98D2D9;
  font-weight: 300;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #98D2D9;
  font-weight: 300;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #98D2D9;
  font-weight: 300;
}

.top {
  position: absolute;
}

.clear {
  clear: both;
}

.input_box {
  position: relative;
}

.headerBottom {
  background-image: url("../images/header_bg.jpg");
  height: 80px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.signup {
  background-color: #0097a9;
  height: 100px;
  margin-top: 70px;
}

.signupButton {
  margin-top: 25px;
}

.biggerSherpaMan {
  display: none;
}

.half {
  padding: 0px 20px;
}

.processTitle {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.state {
  float: none;
  font-size: 13px;
  color: #8A969C;
  width: 100%;
  max-width: 220px;
  text-align: left;
  margin: 0 auto;
}

.state ul {
  padding-left: 22px;
  color: #e3ecf0;
  font-size: 12px;
  line-height: 25px;
  position: relative;
}

.map {
  background-color: #263238;
  color: white;
  line-height: 24px;
  padding: 30px 20px;
  min-height: 490px;
}

.australia {
  background-image: url("../images/map.png");
  width: 270px;
  height: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
}

.appLogo {
  height: 40px;
  width: 123px;
  margin: 15px 5px 25px 5px;
}

.socialFB {
  width: 8px;
  height: 15px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 20px;
}

.socialLogo {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 20px;
}

.apps {
  height: 50px;
  width: 270px;
  margin: 0 auto;
}

.apps a {
  float: left;
}

.itunes {
  width: 123px;
  height: 40px;
  background-image: url("../images/apple.png");
}
 
.googleplay {
  width: 123px;
  height: 40px;
  background-image: url("../images/googleplay.png");
}

.header {
  background-color: #0097a9;
  padding: 14px;
  color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.header h1 {
  font-weight: 400;
  font-size: 28px;
}

.process {
  color: black;
}

.process h4 {
  color: #0097a9;
}

.everyonTitle {
  background-image: url("../images/everyone_sherpa.png");
  height: 12px;
  margin-top: 3px;
  width: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  float: right;
  margin-right: 30px;
  top: 10px;
  position: relative;
}

.facebook {
  background-image: url("../images/facebook.png");
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
}

.instagram {
  background-image: url("../images/instagram.png");
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  margin-right: 10px;
}

.linkedin {
  background-image: url("../images/linkedin.png");
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
}

.logo {
  background-position: -1350px -1211px;
  width: 150px;
  height: 58px;
  clear: both;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  top: 30px;
}

.bottomArrow {
  background-image: url("../images/bottom_arrow.png");
  width: 110px;
  height: 108px;
  clear: both;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
  top: 17px;
}

.sherpaMan {
  width: 150px;
  height: 228px;
  background-image: url("../images/small_man.png");
  clear: both;
  margin: 0 auto;
  margin-top: 50px;
}

.processDesc {
  min-height: 60px;
}

.signupDesc {
  font-size: 16px;
  color: black;
  margin: 0 auto;
}

.dollar {
  width: 120px;
  height: 98px;
  background-image: url("../images/dollar.png");
  margin: 0 auto;
}

.smile {
  width: 120px;
  height: 98px;
  background-image: url("../images/smile.png");
  margin: 0 auto;
}

.flexible {
  width: 120px;
  height: 98px;
  background-image: url("../images/flexible.png");
  margin: 0 auto;
}

.requirement {
  background-image: url("../images/requirement_bg.jpg");
  width: 100%;
  height: 100%;
  background-size: contain;
  color: white;
  padding: 20px;
}

.requirement h1 {
  margin: 70px 0px 30px 0px;
  font-weight: 300;
}

.requirement .requiredItem {
  margin: 30px 0px;
  min-height: 150px;
}

.location {
  width: 100px;
  height: 83px;
  background-image: url("../images/location.png");
  margin: 0 auto;
  margin-bottom: 15px;
}

.vehicle {
  width: 100px;
  height: 83px;
  background-image: url("../images/vehicle.png");
  margin: 0 auto;
  margin-bottom: 15px;
}

.phone {
  width: 100px;
  height: 83px;
  background-image: url("../images/phone.png");
  margin: 0 auto;
  margin-bottom: 15px;
}

.license {
  width: 100px;
  height: 83px;
  background-image: url("../images/license.png");
  margin: 0 auto;
  margin-bottom: 15px;
}

.friendly {
  width: 100px;
  height: 83px;
  background-image: url("../images/friendly.png");
  margin: 0 auto;
  margin-bottom: 15px;
}

.communication {
  width: 100px;
  height: 83px;
  background-image: url("../images/communication.png");
  margin: 0 auto;
  margin-bottom: 15px;
}

.lamma {
  width: 117px;
  height: 45px;
  background-image: url("../images/lamma.png");
  position: absolute;
}

.launch {
  width: 25px;
  height: 25px;
  background-image: url("../images/launch.png");
  bottom: 0;
  left: 74px;
}

.abn {
  width: 100px;
  height: 83px;
  background-image: url("../images/abn.png");
  margin: 0 auto;
  margin-bottom: 15px;
}

.over18 {
  width: 100px;
  height: 83px;
  background-image: url("../images/over18.png");
  margin: 0 auto;
  margin-bottom: 15px;
}

.feature {
  padding: 20px 40px 20px 40px;
  color: black;
  line-height: 30px;
}

.feature h1 {
  color: #0097a9;
  line-height: 55px;
  font-weight: 400;
}

.feature h4 {
  color: #0097a9;
  line-height: 35px;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0px;
}

.feature p {
  line-height: 25px;
}

.faq {
  text-align: center;
  height: 100px;
}

.faq a {
  color: #0097a9;
  font-weight: 700;
  text-decoration: underline;
}

.money {
  width: 100px;
  height: 86px;
  background-image: url("../images/money.png");
  margin: 0 auto;
}

.profile {
  width: 100px;
  height: 86px;
  background-image: url("../images/profile.png");
  margin: 0 auto;
}

.background {
  width: 108px;
  height: 92px;
  background-image: url("../images/background.png");
  margin: 0 auto;
}

.insurance {
  width: 108px;
  height: 92px;
  background-image: url("../images/insurance.png");
  margin: 0 auto;
}

.bubble_abn {
  width: 100px;
  height: 86px;
  background-image: url("../images/bubble_abn.png");
  margin: 0 auto;
}

.bubble_license {
  width: 100px;
  height: 86px;
  background-image: url("../images/bubble_license.png");
  margin: 0 auto;
}

.dealName {
  margin-top: 15px;
  margin-bottom: 15px;
}

.deal_alcohol {
  width: 130px;
  height: 130px;
  background-image: url("../images/deal_alcohol.png");
  margin: 0 auto;
}

.deal_cakes {
  width: 130px;
  height: 130px;
  background-image: url("../images/deal_cakes.png");
  margin: 0 auto;
}

.deal_docs {
  width: 130px;
  height: 130px;
  background-image: url("../images/deal_docs.png");
  margin: 0 auto;
}

.deal_flowers {
  width: 130px;
  height: 130px;
  background-image: url("../images/deal_flowers.png");
  margin: 0 auto;
}

.deal_pr {
  width: 130px;
  height: 130px;
  background-image: url("../images/deal_pr.png");
  margin: 0 auto;
}

.footer {
  background-color: #e3ecf0;
  width: 100%;
  padding: 20px;
  min-height: 100px;
}

.footer a {
  color: #0097a9;
}

.footerContents {
  margin: 0 auto;
  font-size: 13px;
}

.apps {
  height: 50px;
  margin: 0 auto;
  padding-left: 10px;
}

.social {
  width: 70px;
  margin: 0 auto;
  position: relative;
  top: -20px;
}

.headerIntro {
  margin-top: 70px;
}

.headerIntro h5 {
  letter-spacing: 2px;
}

.headerIntro h1 {
  line-height: 40px;
}

.mapTitle {
  font-weight: 400;
  font-size: 25px;
  color: #0097a9;
  padding: 30px 0px 40px 0px;
  line-height: 40px;
}

.facebook {
  top: 10px;
  position: relative;
}

.instagram {
  top: 10px;
  position: relative;
}

.linkedin {
  top: 10px;
  position: relative;
}

.featureDesc {
  margin-bottom: 20px;
  line-height: 20px;
}

.feature p {
  font-size: 16px;
}

.dealGroup {
  background-color: #F6F9FB;
  min-height: 380px;
  padding-top: 35px;
}

.dealGroup h1 {
  margin-bottom: 30px;
  margin-top: 0px;
}

.requirement {
  background-image: url("../images/requirement_bg.jpg");
  width: 100%;
  height: 100%;
  background-size: contain;
  color: white;
  padding: 20px;
}

.requirement h1 {
  margin: 70px 0px 40px 0px;
  font-weight: 400;
}

.requirement .deailItem {
  margin: 30px 0px;
  min-height: 150px;
}

@media screen and (min-width: 900px) {
  .homepage {
    float: left;
    margin-top: 25px;
    margin-right: 10px;
  }
  .footerContents {
    width: 900px;
  }
  .apps {
    float: left;
  }
  .social {
    float: left;
    margin-top: 10px;
    top: 0px;
  }
 .float {
    float: left;
    width: 28%;
    margin-bottom: 30px;
    padding: 0px 20px 60px 20px;
    min-height: 230px;
  }
}



@media screen and (min-width: 780px) {
  .van {
    display: block;
  }
  .dealItem {
    float: left;
    width: 20%;
    height: 150px;
  }
  .featureDesc {
    margin-bottom: 0px;
  }
  .headerContents {
    max-width: 960px;
    margin: 0 auto;
    position: relative;
  }
  .header {
    min-height: 580px;
    position: relative;
  }
  .header h1 {
    font-weight: 400;
    font-size: 48px;
  }
  .everyonTitle {
    float: right;
    margin-right: 30px;
    top: 10px;
    position: relative;
  }
  .logo {
    position: absolute;
    margin: 0;
    top: 10px;
  }
  .everyonTitle {
    float: right;
    margin-right: 30px;
    top: 10px;
    position: relative;
  }
  .headerIntro {
    float: left;
    width: 300px;
    text-align: left;
    padding: 20px;
  }
  .biggerSherpaMan {
    display: block;
    width: 308px;
    height: 468px;
    background-image: url("../images/big_man.png");
    right: 0px;
    top: 65px;
	position: absolute;
  }
  .cloud {
    width: 540px;
    height: 193px;
    background-image: url("../images/cloud.png");
    right: 0;
	position: absolute;
    top: 0px;
  }
  .sherpaMan {
    display: none;
  }
  .state {
    float: left;
    font-size: 13px;
    color: #8A969C;
    width: 33%;
    max-width: 220px;
    text-align: left;
  }
 
  .process {
    height: 690px;
  }
  .headerIntro h1 {
    line-height: 60px;
  }
  .feature, .requirement {
    clear: both;
  }
  .feature {
    padding: 60px 40px 50px 40px;
    margin-bottom: 80px;
  }
  .requirement {
    min-height: 650px;
    background-size: cover;
    background-image: url("../images/driver.jpg");
  }
  .requiredItem {
    float: left;
    width: 25%;
    height: 150px;
  }
  .half {
    float: left;
    width: 44%;
    height: 220px;
  }
  .signup {
    height: 170px;
    margin-top: 0px;
  }
  .signupButton {
    margin-top: 60px;
  }
  .completed {
    max-width: 500px;
    margin-top: 100px;
    margin-left: 20px;
  }
  .completed h1 {
    font-weight: 200;
    font-size: 48px;
    line-height: 65px;
  }
  .mapContents {
    width: 65%;
    float: right;
    text-align: left;
    padding-left: 10px;
  }
  .processDesc {
    max-width: 300px;
    margin: 0 auto;
  }
  .wrap {
    max-width: 960px;
    margin: 0 auto;
  }
  .australia {
    width: 33%;
    float: left;
  }
  .footer {
    clear: both;
  }
}

@media screen and (max-width: 400px) {
  .lamma {
    display: none;
  }
}

@media screen and (max-width: 920px) {
  .lamma {
    display: none;
  }
  .everyonTitle {
    float: none;
  }
  .dealGroup {
    margin-top: 0px;
    min-height: 420px;
  }
}